.toast {
  position: fixed;
  bottom: 20px; /* Keep it at the bottom */
  left: 50%; /* Move it to the center */
  transform: translateX(-50%); /* Center it perfectly */
  padding: 12px 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold; /* Make text bold */
  color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  animation: fadeInOut 3s ease-in-out;
  white-space: nowrap; /* Prevents breaking into multiple lines */
}

.toast.success {
  background-color: #4caf50; /* Green */
}

.toast.error {
  background-color: #f44336; /* Red */
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translate(-50%, 20px);
  }
  10% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(-50%, 20px);
  }
}
